<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="">
          <title></title>

          <b-row>
            <b-col md="6" xl="2">
              <validation-provider #default="{ errors }" name="ToPerson" rules="required">
                <b-form-group
                  label-for="toperson"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الشخص المعني</label>
                  <v-select
                    v-model="person_id"
                    @open="Belongsto"
                    :options="optionTo"
                    :reduce="(val) => val.value"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="3">
              <validation-provider #default="{ errors }" name="service" rules="required">
                <b-form-group
                  label-for="service"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة</label>
                  <v-select
                    v-model="Mainservice"
                    :reduce="(val) => val.value"
                    :options="optionService"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules="required"
              >
                <b-form-group
                  label-for="cardNumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة الفرعية </label>
                  <v-select
                    v-model="proposal_service_id"
                         :multiple="true"
                    :reduce="(val) => val.value"
                    :options="optionSubServices"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="4">
              <b-form-group label-for="delimiter">
                <validation-provider #default="{ errors }" name="noteservices" rules="">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-default"
                    :state="errors.length > 0 ? false : null"
                    v-model="notes"
                    placeholder="الملاحظات"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <br />
            <br />
          </b-row>
          <br />
          <br />
          <br />

          <br />
          <br />
        </b-card-code>
        <b-row>
          <b-col md="12" xl="4"></b-col>
          <b-col
            class="border-Primary d-flex justify-content-between flex-column mt-1 mt-sm-0"
            md="12"
            xl="3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="addservice"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle"> إضافة الخدمة </span>
            </b-button>
          </b-col>
          <br />
          <br />
        </b-row>
        <br />
        <br />
      </b-form>
    </validation-observer>
    <!-- <b-row>
      <b-col >
     <b-button  variant="gr"  @click="last" >
                <span > العودة الى صفحة اضافة المستفيد</span>
              </b-button></b-col>
               <b-col >  <b-button  class="flo" variant="gr" @click="next">
                <span> الانتقال الى معلومات البطاقة</span>
              </b-button></b-col>
             </b-row> -->
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
import { FormWizard, TabContent } from "vue-form-wizard";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import Ripple from "vue-ripple-directive";
import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import vueCountryRegionSelect from "vue-country-region-select";
// import vueCountriesCities from "vue-countries-cities";
export default {
  name: "service",

  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    FormWizard,
    TabContent,
    BCardCode,
    BForm,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    ToastificationContent,
    BRow,

    BCol,
  },

  data() {
    return {

          person_id: '',
          proposal_service_id: [],
          notes: '',
       

      selectedCountry: '',
      selectedCity: '',
      country: '',
      optionTo: [],
      region: '',
      selected2: '',
      selectedService: '',
      number: '',
      notes: '',
      value: '',
      selected: '',
      option: [{ title: "العائلة" }],
      optionService: [],
      id: '',
      optionSubServices: [],
      Mainservice: '',
    };
  },

  watch: {
    Mainservice: function() {
     this.optionSubServices = [];
 
        let url = `/api/v1/main_services/${this.Mainservice}/proposal_services`;
        this.$http.get(url).then((res) => {

          let subServices = res.data.data;
          subServices.forEach((el) => {
            this.optionSubServices.push({ label: el.name, value: el.id });
          });
        });
    }
  },

  directives: {
    Ripple,
  },

  created() {
    this.getServiceType();
  },

  mounted() {
    // window.setInterval(() => {
    // this.Belongsto();
    // }, 5000);
  },

  methods: {

    addservice() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          this.id = this.$store.state.id;
          if (success) {
            let data = null;


              if (this.person_id == this.id) {
                data = {
                  proposal_service_id: this.proposal_service_id,
                  notes: this.notes,
                  family_id: this.id,
                };
              } else {
                data = {
                  proposal_service_id: this.proposal_service_id,
                  notes: this.notes,
                  person_id: this.person_id,
                };
              }



            let url=null
            if (data.family_id == this.id) {
              url = `/api/v1/families/family_proposal_services`;
            } else {
              url = `/api/v1/persons/proposal_services`;
            }

            this.$http
              .post(url, data)
              .then(() => {
                this.$emit("getFamilySer", this.id);
                this.$swal({
                  title: "",
                  text: `تم إضافة الخدمة   بنجاح   `,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

        this.person_id= "",
        this.proposal_service_id= [],
        this.notes= "",
        this.Mainservice= "",

                requestAnimationFrame(() => {
                  this.$refs.simpleRules.reset();
                });
              })
              .catch((error) => {

                   this.$swal({
                title: "",
                text:`${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

              });
          }
        });
      });
    },

    Belongsto() {
      this.optionTo = [];
      this.id = this.$store.state.id;
      let url = `/api/v1/families/${this.id}/get_persons_for_applying_services`;
      this.$http.get(url).then((response) => {
        let persons = response.data.data;
        persons.forEach((el) => {
          this.optionTo.push({ value: el.id, label: el.first_name });
        });
      });
      // }
    },
   

    getServiceType() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {

        let proposal_services = res.data.data.main_services;
        proposal_services.forEach((el) => {
          this.optionService.push({ label: el.name, value: el.id });
        });
      });
    },
  }
};
</script>

<style scoped>
.btn-gr {
  color: white;
  background-color: #00468e !important;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 25px;
}
.flo {
  margin-left: 300px;
}
</style>